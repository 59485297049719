import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../components/layout'
import Grid from '@material-ui/core/Grid';
import PraktijkBalkeLogo from '../../images/brands/logo-praktijkbalke.png';
import ReturnArrow from '../../images/return-arrow.svg';

const Praktijkbalke = () => (
  <Layout>
    <Grid container
          className="link-list"
    >
      <Grid item xs={4}>
        <div>
          <Link to="/projects/"><img src={ ReturnArrow } className="return-arrow" width='30px' alt="Return Logo" /></Link>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div>
          <Link to="/about/">About</Link>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div>
          <Link to="/contact/">Contact</Link>
        </div>
      </Grid>
    </Grid>
      <Grid container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={8}>
        <div className='app-intro'>
          <Link to="projects/">Projects</Link>
          <Grid item xs={12}>
                 <span>
              <Link className="arrow" to="/projects/purplebean">&#x2039; </Link>
            </span>
            <h2 className="inline"> Praktijk Balke </h2>
            <span >
              <Link className="arrow" to="/projects/defysioman">&#x203A;</Link>
            </span>
          </Grid>
          <img src={ PraktijkBalkeLogo } className="project-detail"  alt="Skills" />
          <Grid container
                direction="row"
                justify="center"
                className="about-content">
            <Grid item md={6}>
              <h3>Summary</h3>
              <p>Implement design and copy, basic informational site.</p>

            </Grid>
            <Grid item md={6}>
              <h3>Stack</h3>
              <p className="subcontent">HTML5, Bootstrap 4, Javascript, Sass/CSS.</p>
              <h3>Period</h3>
              <p className="subcontent">2018 - present</p>
              <h3>URL</h3>
              <a className="white" href='http://praktijkbalke.nl' rel="noopener noreferrer"  target="_blank" alt="praktijk Balke"> <h4>Praktijkbalke.nl </h4></a>

            </Grid>
          </Grid>
        </div>
      </Grid>
  </Layout>
)

export default Praktijkbalke;
